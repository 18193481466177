/*Desktop View*/
.App {
  width: 100vw;
  height: 100vh;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
  color: #000;
}
.swiper-pagination-bullet {
  background-color: #000 !important;
}
/* Navbar */

.navbar-toggler {
  display: none;
}
.logo-dark {
  width: 135px;
  height: 50px;
  margin-left: auto;
}
.nav-options {
  right: 0;
  margin-left: auto;
  float: end;
  display: flex;
  grid-template-columns: repeat(3, auto);
  grid-gap: 30px;
  list-style-type: none;
}
.option {
  display: inline-block;
  color: white;
  text-decoration: none;
}
.option :link {
  width: 100px;
  overflow: visible;
  font-weight: 600;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: none;
  padding-right: 16px;
}
.hashlink {
  color: #ffffff;
}
.option :hover {
  color: #f31c1c;
}

/* Home Page */
.frame {
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #6bc9ff 0%, hsl(0, 0%, 100%) 100%);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.warning {
  color: #ff493d;
}
.building {
  display: none;
}
/* .btn {
  margin-left: 276px;
} */
/* .spinner {  
  display: flex;
  justify-content: center;
  align-items: center;
} */
.carousel-wrapper {
  width: 100%;
}
.typewritter-text {
  overflow: hidden;
  white-space: pre-wrap;
  margin-top: 20px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 500;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: var(--silver, #45454a);
  font-size: 30px;
  text-align: left;
}

.home-frame {
  width: 433px;
  overflow: visible;
  transform: rotate(-90deg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 10px;
  top: -20px;
}

/* Service */
.service-section {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

/* .card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.05, 1.05);
} */
.mobile-section {
  display: none;
}
.service-box {
  width: 665px;
  height: 303px;
  overflow: hidden;
}
.rectangle-15 {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: end;
  border-radius: 25px;
  width: 294px;
  margin-top: -120px;
}
.phone {
  margin-left: 100px;
}
.spreadsheet {
  margin-top: 50px;
  margin-left: 10px;
  background-image: url("../images/spreadsheet.svg");
}
.cell1 {
  width: 54px;
  height: 12px;
  margin-top: -280px;
  margin-left: 125px;
}
.cell2 {
  width: 27px;
  height: 12px;
  margin-top: -210px;
  margin-left: 45px;
}
.cell3 {
  width: 27px;
  height: 12px;
  margin-top: -230px;
  margin-left: 150px;
}
/* Product */
.product-section {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.product-img1 {
  height: 230px;
  margin-top: 10px;
}
.product-img3 {
  height: 230px;
  margin-top: -250px;
}
.product-img4 {
  height: 130px;
  margin-top: -208px;
  margin-left: 28px;
}
.product-img2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: end;
  background-attachment: fixed;
  transform: rotate(0deg);
  margin-top: -280px;
  margin-left: 110px;
}
.retail_phone {
  margin-top: 50px;
  margin-left: 100px;
}
/* Client */
.client {
  padding-top: 20px;
  padding-bottom: 40px;
  overflow: hidden;
  background-color: #c9e7f8;
  width: 100%;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.client-container {
  margin: 0 auto;
  width: 980px;
}
.styles-card1 {
  position: absolute;
  width: 998px;
  height: 313px;
  background: rgba(255, 255, 255, 0.51);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(30px);
  border-radius: 20px;
}
.color-bg {
  background: linear-gradient(
    180deg,
    rgba(168, 218, 220, 0.67) 29.69%,
    #ffd1c2 100%
  );
}
.quote-image {
  position: absolute;
  top: 0%;
  left: 50px;
  width: 53px;
  height: 100px;
  opacity: 0.39;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.quote-dark-image {
  position: absolute;
  top: 0;
  left: 0px;
}
.swiper-container {
  box-shadow: none;
}
.swiper-slide {
  width: 746px;
}
.swiper-wrapper {
  margin-bottom: 30px;
}
.client-header {
  margin-left: 30%;
}
.wave-image {
  position: absolute;
  margin-top: 0;
  width: 100%;
  top: -50px;
}
/* Contact */
.footer-bg-test {
  width: 100%;
  overflow: hidden;

  background-image: url("../images/footer_bg_test.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.footer-content {
  width: 100%;
  background-color: var(--silver, #45454a);
  overflow: hidden;
  margin-top: 230px;
  margin-bottom: 100px;
  align-items: center;
  justify-content: center;
}

/* Form css */
.select {
  font-size: 16px;
  width: 100%;
  padding: 15px;
  margin: 8px;
  border-radius: 8px;
  color: #333;
  background-color: #ebebeb;
  border: rgba(0, 0, 0, 0);
}
.select :focus {
  border-color: #ff8057;
}
.form {
  padding-right: 15px;
}
.form-control {
  width: 100%;
  padding: 25px;
  margin: 8px;
  font-size: 16px;
  background-color: #ebebeb;
  border: rgba(0, 0, 0, 0);
  border-radius: 8px;
  color: #333;
  border-width: 1px;
  align-items: center;
  cursor: pointer;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f7f9fe;
  color: #000;
  border-color: #ff8057;
  outline: rgba(0, 0, 0, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #aaa;
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  color: #aaa;
  font-size: 16px;
}

.form-control::-ms-input-placeholder {
  color: #aaa;
  font-size: 16px;
}

.form-control::placeholder {
  color: #aaa;
  font-size: 16px;
}
.form-label {
  color: black;
}
.error {
  margin: 8px 0px;
  display: none;
  color: #ee4a4a;
}
.error_message {
  padding: 10px;
  margin-bottom: 20px;
  color: #ff493d;
  font-size: 14px;
}
/* Footer */
.footer-last {
  width: 100%;
  background-color: var(--token-ec02e069-cace-4ed4-99ab-0b0e9a2d233f, #ff493d);
  overflow: hidden;
  height: 50px;
}
.footer-link {
  float: left;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  margin-left: auto;
}
.footer-links {
  float: right;
  margin-top: -40px;
  margin-right: -300px;
}

.ft-legal {
  padding: 0.9375rem 1.875rem;
  background-color: #ff493d;
}
.ft-legal-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.ft-legal-list li {
  margin: 0.125rem 0.625rem;
  white-space: nowrap;
}
/* one before the last child */
/* .ft-legal-list li:nth-last-child(2) {
  flex: 1;       
} */

/* SubFooter */
.footer-background {
  width: 100%;
  background-color: var(--silver, #45454a);
  overflow: hidden;
  text-align: center;
}
.footer-section {
  width: auto;
  overflow: visible;
  letter-spacing: 0px;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  font-size: 16px;
  text-align: start;
  margin-left: 50px;
  color: #ffffff;
}
.footer-subsection {
  text-align: start;
  margin-left: 85px;
}
.text-dark-footer {
  color: white !important;
  font-size: 20px;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
    "Helvetica Neue", Arial, sans-serif;
}
.sub-footer-links {
  display: none;
}
.company-sub-menu li {
  padding: 8px 0;
  list-style: none;
}
.footer-title {
  width: 90px;
  height: 15px;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  letter-spacing: 0px;
  line-height: 1;
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
}
.company-sub-menu li :hover {
  color: #ee4a4a !important;
}
.icons {
  color: gray;
  font-size: 20px;
  margin-right: 15px;
  margin-top: 10px;
}

/* CareerPage */
.career-frame {
  width: 100%;
  height: 644px;
  background: linear-gradient(180deg, #6bc9ff 0%, hsl(0, 0%, 100%) 100%);
  overflow: visible;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.career {
  margin-left: auto;
}
.logo-image {
  position: absolute;
  top: 200px;
  right: 100px;
  width: 165px;
  height: 62px;
}

/* Hiring */
.hiring {
  width: 100%;
  overflow: hidden;
  margin-top: 80px;
  margin-bottom: 150px;
}

.hiring-container {
  position: relative;
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;
}

/* Career Footer */
.career-footer-bg-test {
  width: 100%;
  overflow: hidden;
  background-image: url("../images/c_footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.career-footer-content {
  width: 100%;
  height: 32vh;
  background-color: var(--silver, #45454a);
  overflow: hidden;
  margin-top: 200px;
  margin-bottom: 100px;
  align-items: center;
  justify-content: center;
}
.footer-span {
  color: #ffffff;
  margin-left: 2px;
}

/* Responsive */
/*Mobile View */
@media only screen and (max-width: 820px) {
  .mobile-creative {
    display: none;
  }
  .navbar-toggler {
    align-self: flex-end;
    display: initial;
    color: #ff493d;
    position: absolute;
    cursor: pointer;
    right: 0;
  }
  .navbar-brand {
    left: 0;
  }
  .nav-options {
    z-index: 1;
    display: block;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 70px;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(53, 48, 48, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }
  .nav-options.active {
    right: 0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    margin-left: auto;
    opacity: 1;
    z-index: 1;
    align-content: left;
    padding-left: 0px;
  }
  .option {
    display: flex;
    right: 0;
    margin-left: 200px;
    align-items: right;
    justify-content: center;
    text-decoration: none;
    padding: 30px 0px;
  }

  .hashlink {
    color: #ffffff;
    width: 100%;
    text-shadow: 1px 1px 1px var(--think, #ffa500);
  }
  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .logo-dark {
    margin-left: -10px;
  }
  .client-container {
    margin-left: 0px;
    width: auto;
  }
  .service-section {
    display: none;
  }
  .product-section {
    display: none;
  }
  .mobile-section {
    display: block;
    margin-top: 10px;
    padding-bottom: 70px;
  }
  .wave-image {
    margin-top: -10px;
    position: absolute;
    margin-top: 0;
    /* width: 100%; */
    top: -0.7%;
  }
  .rectangle-15 {
    margin-left: 10px;
    margin-top: -100px;
    margin-bottom: 20px;
    width: 250px;
  }
  .phone {
    margin-left: 60px;
  }
  .spreadsheet {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: -40px;
  }
  .cell1 {
    margin-left: 160px;
    margin-top: -200px;
  }
  .cell2 {
    margin-left: 110px;
    margin-top: -195px;
  }
  .cell3 {
    margin-left: 220px;
    margin-top: -195px;
  }
  .product-img1 {
    margin-left: 60px;
  }
  .product-img3 {
    margin-top: -250px;
    margin-left: 50px;
  }
  .product-img4 {
    margin-top: -208px;
    margin-left: 80px;
  }
  .building_shadow {
    margin-left: 20px;
  }
  .product-img2 {
    margin-left: 130px;
    width: 130px;
  }
  .retail_phone {
    margin-left: 120px;
  }
  .client-header {
    margin-left: 0%;
  }
  .modal1 {
    color: var(--silver, #ffffff);
    position: fixed;
    top: auto;
    bottom: 0;
    width: 100% !important;
    bottom: 0%;
    max-width: 1080px;
    left: 48%;
    transform: translate(-50%) !important;
  }
  .modal2 {
    color: var(--silver, #ffffff);
    position: fixed;
    top: auto;
    width: 100% !important;
    bottom: 0;
    max-width: 1080px;
    left: 48%;
    transform: translate(-50%) !important;
  }
  .modal-content {
    background-color: var(--silver, #45454a);
    border-radius: 10px;
  }
  .client {
    overflow: hidden;
  }
  .footer-background {
    padding-top: 20px;
    /* margin-bottom: 10px; */
    text-align: start;
  }
  .footer-section {
    margin-left: 0px;
  }
  .footer-subsection {
    margin-left: 0px;
  }
  .footer-content {
    height: auto;
    margin-top: 200px;
    margin-bottom: 30px;
  }
  .footer-last {
    height: 8vh;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .footer-link {
    margin-left: auto;
    justify-content: center;
    align-items: center;
  }
  .sub-footer-links {
    display: flex;
    overflow: hidden;
  }
  .career-footer-content {
    margin-bottom: 30px;
  }
  .career {
    margin-left: 0px;
  }
  .hiring-content {
    margin-left: 50px;
  }
  .icons {
    margin-left: auto;
    color: gray;
    font-size: 30px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 410px) {
  .building {
    display: block;
    width: 398px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 410px) and (max-width: 500px) {
  .building {
    display: block;
    width: 470px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 500px) and (max-width: 550px) {
  .building {
    display: block;
    width: 530px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 550px) and (max-width: 600px) {
  .building {
    display: block;
    width: 570px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 600px) and (max-width: 650px) {
  .building {
    display: block;
    width: 630px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 650px) and (max-width: 700px) {
  .building {
    display: block;
    width: 670px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 700px) and (max-width: 750px) {
  .building {
    display: block;
    width: 730px;
    height: 200px;
    margin-top: 50px;
    overflow: visible;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
