.slick-arrow-next:before,
.slick-arrow-prev:before {
  color: #808080 !important;
  font-size: 30px !important;
  margin-left: auto;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -5px !important;
}

.swiper-button-prev {
  left: -8px !important;
  z-index: 555;
}

.swiper-button-next {
  right: -8px !important;
  z-index: 555;
}

.mySwiper {
  padding: 1rem;
  width: 75vw;
}

.service-section {
  position: relative;
}

.wave-image-1 {
  position: absolute;
  width: 100%;
  top: -10%;
}

.cin-number {
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .mySwiper {
    padding: 0rem;
    width: auto;
  }
  .wave-image-1 {
    position: absolute;
    width: 100%;
    top: -1.5%;
  }
}
